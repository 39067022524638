.task-name {
    font-size: 24px;
    font-weight: 500;
    line-height: 31.2px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
}

.detail-row{
    display: flex;
    align-items: center;
    gap: 80px;
}
.detail-row .containerStyle {
    margin-bottom: 0px;
}
.task-detail-row .task-detail-row-label{
    padding: 8px;
    font-size: 12px;
}
.task-detail-row .task-detail-row-value{
    padding: 8px;
    font-size: 14px;
}