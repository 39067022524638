.imageCollection{
    display: flex;
    align-items: center;
}
.containerStyle{
    display: flex;
    align-items:center;
    margin-bottom: 10px;
}
.count{
    font-family: "DM Sans";
font-size: 12px;
font-weight: 400;
line-height: 15.62px;
text-align: left;

}