.main-wraper{
max-width: 1675px;
width: 100%;
}
.section-header {
    background-color: rgba(248, 248, 248, 1);
    margin: 30px 0px 0px 30px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
}

.section-left {
    display: flex;
    align-items: center;
    gap: 10px;
}
.section-left .input{
    max-width: 150px; /* Adjust this value based on your needs */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    padding: 8px 16px; 
}
.section-drag-icon {
    margin: 14px 10px 15px 10px;
    cursor: move;
}

.section-left input {
    border: none;
    background: transparent;
    padding-left: 0px;
}

.section-left input:focus {
    background: white;
    border: 1px solid rgba(240, 241, 243, 1);
}

.section-tags {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 20px 10px 60px;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 25.6px;
    text-align: left;
}

.section-table {
    border-top: 1px solid rgba(232, 233, 237, 1);

}

.tasks-table {
    /* max-width: 1206px; */

    margin: 0px 0px 0px 30px;
    border-collapse: collapse;
}
.tasks-table tr td{padding: 2px 10px;}
.tasks-table thead{
    width: 100%;
}
.tasks-table th {
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500 !important;
    line-height: 20.8px;
    text-align: left;
    padding-bottom: 20px;
}
.tasks-table tbody {
    margin-right: 30px;
    border-bottom: 1px solid rgba(232, 233, 237, 1);
}

.tasks-table tbody tr {
    position: relative;
    border-bottom: 1px solid #ddd;
}
.tasks-table tbody tr td{
    border-right: 1px solid #ddd
}
.taskNameCell{
    font-size: 14px;
}

.taskNameCell img {
    width: 12px;
    height: 12px;
    margin-right: 10px;
}

.taskNameCell .input{
    font-size: 14px;
}


.tasks-table-header {
    /* display: flex; */
    justify-content: space-between;
    color: rgba(154, 154, 154, 1);
}

.table-header-right {
    display: flex;
    gap: 50px;
}
.section-tags,
.section-table {
    transition: max-height 0.3s ease-in, opacity 0.3s ease-in;
    /* max-height: 1000px; */
    opacity: 1;
    overflow-x: auto;
}

.section-tags.hidden,
.section-table.hidden {
    display: none;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
}
.section-dropdown-icon {
    transition: transform 0.3s ease;
}

.section-dropdown-icon.rotate {
    transform: rotate(-90deg);
}

.tasks-table th {
    position: relative;
    text-align: left;
    padding: 18px 8px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

.tasks-table thead:hover .resize-handle {
    background-color: rgba(0, 0, 0, 0.1);  
    position: absolute;
    top: 0;
    right: 0; 
    width: 3px;  
    height: 100%;
    cursor: col-resize; 
    z-index: 1;
}


.tasks-table thead:not(:hover) .resize-handle {
    display: none;
}

  .resize-handle {
    cursor: col-resize;
    display: inline-block;
    width: 1px;
    height: 100%;
    background: #ccc;
    position: absolute;
    right: 0;
    top: 0;
  }
  .tasks-table .tasks-table-header th {
    border-right: 1px solid #ddd
  }
  .tasks-table-header td:nth-child(1) input {
    width: calc(100% - 50px);
}
.tasks-table-header th:nth-child(1){
    padding-left: 28px;
}

th, td {
    box-sizing: border-box; 
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    height: 100%; 
    overflow: hidden;
    justify-content: space-between;
}

.sidebar-container > * {
    flex-shrink: 0; /* Prevent shrinking of children */
}
.sideBar-taskDetails{

}
.sidebar-container .sideBar-taskDetails{
        padding: 20px;
}
.sidebar-container > *:last-child {
    margin-top: auto; /* Push the Comments component to the bottom */
}
.flex-filler {
	width: 0;
	height: 0;
	flex-grow: 1;
}
.comemnt{
    position: absolute;
    bottom: 40px;
}
.align-center{
    display: flex;
    align-items: center;
}