.sidebar {
  width: 165px;
  background-color: rgba(246, 247, 250, 1);
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  align-items: center;
  height: 100%;
}

.wraper {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 30px;
  color: rgba(55, 135, 235, 1);
  font-size: 26.25px;
  font-weight: 500;
  line-height: 42px;
  text-align: left;
  font-family: "Poppins";
}

.nav-links {
  flex: 1;
  padding: 0px;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-align: center;
  color: rgba(154, 154, 154, 1);
}

.nav-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-links li {
  display: flex;
  align-items: center;
  margin: 20px 0;
  font-family: "DM Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
  gap: 10px;
  background: rgba(255, 255, 255, 1);
  padding: 21px 15.5px;
}

.nav-links li.active {
  color: rgba(55, 135, 235, 1);
}

.icon {
  margin-right: 10px;
}

.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-profile {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.profile-pic {
  width: 65px;
  height: 65px;
  border-radius: 12px;
  border: 2px;
  object-fit: cover;
  border: 2px solid rgba(232, 233, 237, 1)
}

.logout {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 1);
  padding: 19px 25px 27px;
  border-radius: 12px;
}