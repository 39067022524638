.card {
    border: 1px solid rgba(237, 238, 244, 1);
    border-radius: 8px;
    padding: 16px;
    background-color: #fff;
    max-width: 300px;
  }
  
  .card-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: rgba(55, 55, 55, 1);
  }
  
  .card-body {
    font-size: 14px;
    color: rgba(100, 100, 100, 1);
    padding: 20px 50px;
  }
  
  /* Variants */
  .card-default {
    background-color: rgba(246, 247, 250, 1);
  }
  
  .card-primary {
    background-color: #e3f2fd;
    border-color: #90caf9;
  }
  
  .card-warning {
    background-color: #FEF6D5;
    border-color: #ffb74d;
  }
  
  .card-success {
    background-color: #e8f5e9;
    border-color: #a5d6a7;
  }
  .card-danger{
    background-color: rgba(255, 243, 243, 1);
    border-color: #ffcdd2;
    /* max-width: 100%; */
  }