.comment1 {
    display: flex;
    align-items: flex-start;
    gap: 5px;
    padding: 15px 20px;
}
.comment-view{
    flex: 1 1;
    overflow-y: auto;
    max-height: 170px;
    /* padding: 10px */
}

.comment-image {
    width: 28px; /* Adjust size as needed */
    height: 28px; /* Adjust size as needed */
    border-radius: 50%; /* Make it circular */
    margin-right: 10px;
}

.comment-content {
    flex: 1;
}

.comment-header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}

.comment-name {
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 600;
    line-height: 23.4px;
    text-align: left;
    text-decoration-skip-ink: none;
    
}

.comment-time {
font-size: 14px;
font-weight: 400;
line-height: 18.2px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}

.comment-message-container {
    position: relative;
}

.comment-message {
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 1.5;
}

.comment-message.collapsed {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    max-height: calc(1.5em * 5);
}

.show-more-button {
    background: none;
    border: none;
    color: #1677ff;
    cursor: pointer;
    padding: 4px 0;
    font-size: 14px;
    margin-top: 4px;
    display: block;
}

.show-more-button:hover {
    text-decoration: underline;
}

.option-icon{
    rotate: 87deg;
}

.comment-delete{
    color: red;
}
.deleted-message{
    color: red;
    font-style: italic;
}
.edited-indicator{
    font-size: 10PX;
}
.dropdown-item{
    display: flex;
    gap: 10px;
    align-items: center;
}

.option-icon {
    cursor: pointer;
}

.comment-options{
    display: flex;
    justify-content:space-between;
}
.comment-header-left{
    display: flex;
    align-items: center;
    gap: 10px
}