.badge {
    display: inline-flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 16px;
    font-size: 12px;
    margin: 2px;
    cursor: default;
    white-space: nowrap;
    transition: all 0.2s ease;
}

.badge:hover {
    opacity: 0.8;
}

/* Optional: Add different variants */
.badge.clickable {
    cursor: pointer;
}

.badge.small {
    padding: 2px 6px;
    font-size: 10px;
}

.badge.large {
    padding: 5px 10px;
    font-size: 14px;
}