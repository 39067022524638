.date-picker {
  border-radius: 4.54px;
  font-family: "DM Sans";
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 11.5px;
  text-align: center;
  color: #323232;
  position: relative;
  display: flex;
  align-items: center;
}

.calender {
  background-color: #fff;
}

.calender .rdp-weekdays .rdp-weekday {
  font-family: "DM Sans";
  font-size: 11.04px !important;
  font-weight: 500 !important;
  line-height: 14.38px;
  text-align: center;
}

.calender .rdp-weeks {
  font-size: 8.84px;
}

.calender .rdp-selected .rdp-day_button {
  border: 0px;
  color: #fff;
  background: #3787EB;
  font-family: "DM Sans";
  font-size: 8.84px;
  font-weight: 400;
  line-height: 11.5px;
  text-align: center;
}

.calender .rdp-month_caption {
  padding-left: 18.83px;
  font-family: "DM Sans";
  font-size: 11.04px;
  font-weight: 700;
  line-height: 13.81px;
  text-align: left;
  color: #3787EB;
  border-bottom: 1px solid #E8E9ED;
}

.calender .rdp-nav {
  display: none;
}

.calender .rdp-chevron {
  display: none !important;
}