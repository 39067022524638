/* src/layouts/MainLayout.css */

.layout {
  display: flex;
  height: 100%;
  /* width: 100vw; */
  /* background-color:  rgba(246, 247, 250, 1); */
  overflow: hidden;
  position: relative;
  
}

.left-content {
  transition: all 0.3s ease;
}
.right-content{
  transition: all 0.3s ease;
  background-color: rgba(246, 247, 250, 1);;
  overflow: hidden;
}