.tabs {
    width: 100%;
    /* background: #ffffff; */
    /* border: 1px solid #ddd; */
    border-radius: 15px;
    height: 100%;
}

.tabs__header {
    background: #f9f9f9;
    border-bottom: 1px solid rgba(221, 221, 221, 1);
    background-color:#ffffff;
}

.tabs__header::after {
    border-bottom: #007BFF;
}

.tabs__button {
    padding: 10px;
    background: #ffffff;
    border: none;
    cursor: pointer;
    text-align: center;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: rgba(154, 154, 154, 1);

    max-width: 150px; /* Adjust this value based on your needs */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    padding: 8px 16px; 
}

.tabs__button:last-child {
    border-right: none;
}
.bottomSection .tab_list{
    display: flex;
    gap: 2px;
    align-self: end;
    height: 55px;
}
.tabs__button--active {
    color: rgba(50, 50, 50, 1);
    border-bottom: 1px solid #007BFF;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

}

.tabs__content {
    /* padding: 15px; */
    background-color: #ffffff;
    height: 100%;
}