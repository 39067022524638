.sidebar-right {
    /* position: fixed; */
    width: 358px;
    height: 100%;
    overflow: auto;
    border-left: 2px solid rgba(237, 238, 244, 1);
    border-radius: 0 0 4px 4px;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
}

.sidebar-right .sidebar-arrow {
    rotate: 180deg;
}

.sidebar-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.header-right {
    display: flex;
    gap: 15px;
    align-items: center;
}

.delete-task {
    color: #FF1818;
}

.sidebar-header .dropdown-item {
    gap: 10px !important;
}

.dropdown-option img {
    rotate: 180deg;
}




.delete-confirm-banner {
    background-color: #FFEAEA;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #FFE0E0;
    z-index: 1000;
}

.delete-icon-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.delete-icon {
    width: 20px;
    height: 20px;
    color: #FF4D4F;
}

.delete-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;

}

.delete-actions {
    display: flex;
    gap: 12px;
}

.delete-actions .button {
    padding: 8px 15px;
    font-family: "DM Sans";
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    text-decoration-skip-ink: none;

}

.cancel-button .button {
    padding: 8px 15px;
    border: 1px solid #FF0000;
    border-radius: 4px;
    background-color: transparent;
    color: #FF0000;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
}

.cancel-button:hover {
    background-color: #FFF5F5;
}

.delete-button .button {
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    background-color: #FF0000;
    color: white;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    border-radius: 8px;
}

.delete-button:hover .button {
    background-color: #FF7875;
}