.comments-section {
    width: 353px;
    max-width: 100%;
    border-bottom-right-radius: 14px;
    background-color: #f9f9f9;
    border: 1px solid #EDEEF4;
    display: flex;
    flex-direction: column;
}

.comments-header {
    font-size: 16px;
    font-weight: 500;
    border-bottom: 1px solid #e0e0e0;
    padding: 11px 0 11px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.comments-container {
  max-height: 378px; 
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 8px;
}
.comments-container .comment .textArea{
  width: 260px;
  max-width: 100%;
}
.comment-view-container {
    flex: 1; 
    overflow-y: auto;
    width: 100%;
    scroll-behavior: smooth; 
}

.comments-container .comment {
    padding: 11px 20px 0px 20px;
    display: flex;
    align-items: flex-start;
}

.profile-image img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 12px;
}

.comment-input {
    flex: 1;
    height: 80px;
    border: none;
    outline: none;
    resize: none;
    border-radius: 8px;
    padding: 8px;
    font-size: 14px;
    color: #333333;
    background-color: #f5f5f5;
}

.comment-input::placeholder {
    color: #b0b0b0;
}

.notification-icon {
    display: flex;
    background: none;
    border: none;
    font-size: 20px;
    color: #9e9e9e;
    justify-content: end;
    width: 100%;
    margin-bottom: 5px;
}
.notification-icon img{
  cursor:pointer
}
.notification-icon .button {
  font-size: 13px;
  font-weight:500;
  padding: 0px 4px;
  margin-right:   6px;
}
.notification-icon .containerStyle{
  margin-bottom: 0px;
}
.notification-icon:hover {
    color: #555555;
}
.toggle-order{
  margin-right: 20px;
  background: #5a6268;
  border-radius:8px;
  display: flex;
  align-items: center
}
.toggle-order .button{
  background: transparent;
  font-size: 13px;
  font-weight:500;
  padding: 3px 5px;
}
.toggle-order img{
  width: 16px;
  height: 16px;
  color: #EDEEF4;
}