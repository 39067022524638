.tag-section {
    margin-bottom: 24px;
}
.tag-filter-wrapper{
    padding: 20px;
}
.section-heading {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333;
}

.tag-content {
    padding: 0 8px;
}

.alphabetical-tags {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.letter-group {
    margin-bottom: 12px;
}

.letter-heading {
    font-weight: 500;
    color: #666;
    margin-bottom: 8px;
    font-size: 14px;
}

.badge-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.no-recent-tags {
    color: #666;
    font-style: italic;
    text-align: center;
    padding: 12px;
}
.reset-button{
    display: flex;
    justify-content: end;
}
.reset-button button{
    font-weight: 100;
}