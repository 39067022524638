.dropdown-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); Dark semi-transparent background */
  z-index: 9998;
}

.dropdown-content {
  background-color: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 9999;
}

.dropdown-wrapper--disabled {
  opacity: 0.5;
}

/* style.css */

/* Basic styles for the dropdown */
.dropdown-content {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  z-index: 9999;

  overflow-y: auto; /* Optional: allow scrolling if content exceeds max height */
}

/* Style for each item in the dropdown */
.dropdown-item {
  padding: 6px;
  cursor: pointer;
}

/* Hover effect */
.dropdown-item:hover {
  background-color: #f0f0f0;    
  border-radius: 4px;
}
.dropdown-item.active {
  background-color: #f0f0f0; /* Example active item styling */
}
