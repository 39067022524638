:root {
  --DM-Sans: "DM Sans";

}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
body{
font-family: "DM Sans";
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@font-face {
  font-family: "DM Sans";
  src: url('./assets/fonts/DMSans-Regular.ttf') format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Regular.ttf');
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.style-scroll::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
.style-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: red;
  border-radius: 8px;
}
.style-scroll::-webkit-scrollbar-thumb {
  background-color: #cecece;
  border-radius: 8px; 
}


.style-scroll-6-px::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  height: 6px;
}
.style-scroll-6-px::-webkit-scrollbar {
  /* width: 2px; */
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 8px;
}
.style-scroll-6-px::-webkit-scrollbar-thumb {
  background-color: #A1A1A1;
  border-radius: 8px; 
}
.overflow-y-auto::-webkit-scrollbar {
  display: none;
}
.style-scroll-2-px::-webkit-scrollbar-track {
  background-color: #2F3990;
  height: 2px;
}
.style-scroll-2-px::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: #f5f5f5;
  border-radius: 2px;
}
.style-scroll-2-px::-webkit-scrollbar-thumb {
  background-color: #959595;
  border-radius: 2px; 
}
