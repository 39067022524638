.tasks-container {
    margin: 20px 20px 20px 0px;
    /* height: 90%; */

    height: calc(100vh - 60px); /* Adjust based on your header height */
    overflow: hidden;
}

.tasks-container .left-content {
  height: 100%;
  overflow: auto;
  /* position: relative; */
}
.tasks-container .tabs__content{
  height: calc(100% - 35px);
}
.tasks-container .right-content {
  height: 100%;

    z-index: 1111;
    gap: 10px;
    /* border-bottom: 1px solid rgba(237, 238, 244, 1); */




}

.right-content-wrapper {
    display: block;
    height: -webkit-fill-available;
    background: #fff;
    border-left: 2px solid rgba(237, 238, 244, 1);
    /* border-right: 2px solid rgba(237, 238, 244, 1); */
}
.topSection{
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
    gap:30px;
}

.topLevel .card {
    max-width: 100%;
}
.bottomSection .tabs__header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bottomSection .tabs__header .button{
  padding: 8px 15px;
}
.bottomLevel {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 35px; 
  }
  
  .bottomLevel .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
     max-width: 100%;
  }
  

.bottomLevel .card .card-body{
    display: flex;
    justify-content: center;
}
  .bottomLevel .card img {
    padding: 0px 20px; 
    max-width: 100%;
  }
  .bottomSection .tabs__header > div button:nth-child(2), 
  .bottomSection .tabs__header > div button:nth-child(1) {
    background-color: rgb(224 233 241);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .bottomSection .tabs__button--active::after {
    /* padding-bottom: 6px; */
    /* padding-bottom: 25px;
    content: ''; */
  }

.Divider{
  border: 1px solid rgba(232, 232, 232, 1);
  height: 35px;
}
  .left-main .hover-button{
    position: absolute;
    top: 20px;
    left: 1694px;
    box-shadow: 5px 9px 10px #ccc;
    border-radius: 10px 0 0 10px;
  }
  .left-main .hover-button button{
    border-radius: 10px 0 0 10px;
  }
  .left-main .hover-button img{
    rotate: 180deg;
  }
  @media (max-width: 1024px) {
    .bottomLevel {
      grid-template-columns: repeat(2, 1fr); 
    }
  }
  
  @media (max-width: 768px) {
    .bottomLevel {
      grid-template-columns: 1fr;
    }
  }
  
@media screen and (max-width: 768px) {
    .tasks-container {
        margin: 10px 10px 10px 0px;
        height: auto;
    }

    .right-content {
        flex-direction: column;
    }

    .right-content-wrapper {
        height: auto;
    }
}
