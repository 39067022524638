.textArea {
    resize: none;
    font-family: "DM Sans";
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    text-align: left;
    color: #323232;
    border: 1px solid #EDEEF4;
    border-radius: 8px;
    padding: 10px;
}

.textArea::placeholder {
    color: #9A9A9A;
}