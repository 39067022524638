.tags {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  gap: 8px;
}

.tag {
  display: flex;
  border: none;
  gap: 8px;
  align-items: center;  
  padding: 5px 10px;
  border-radius: 26px;
  background-color: #F0F0F0;
  color: #323232;
  font-size: 14px;
  cursor: default;
  font-family: "DM Sans";
  font-weight: 500;
  text-align: left;
  position: relative;
  max-width: 83px;
}
.tag span{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition:  0.3s ease; 
}

.tag:hover span  {
  max-width: calc(100% - 10px);
  min-width: 0;
}
.tag:hover{
  display: flex;
  gap: 8px;
  min-width: 15px;
}
.tag .input{
  background-color: transparent;
  border: 0px solid none;
  padding: 0px;
  width: 62px;
  font-size: 14px;
}
.hide{
  display: none;
}
.tag .input:focus{
  outline: none;
}
.tag--disabled {
  background-color: rgba(244, 244, 244, 1);
  color: rgba(206, 206, 206, 1);
  padding: 5px 10px;
  border-radius: 16px;
  cursor: pointer;
  font-family: "DM Sans";
  font-size: 14px;
  font-weight: 500;
  text-align: left; 
}

.tags--disabled .tag {
  pointer-events: none;
}


button.tag__remove-button {
  cursor: pointer;
  border: 0;
  background: transparent;
  position: absolute;
  right: 1px; 
  opacity: 0;
  display: flex;
  align-items: center;
  transition: opacity 0.3s ease, right 0.3s ease; 
}

.tag:hover button.tag__remove-button {
  display: block; 
  opacity: 1; 
  transition: opacity 0.3s ease; 
}
.tag--remaining{
  background: #fff;
  border: 1px solid #323232;
}