/* .button {
    color: #FFFFFF;
    padding: 12px 15px 12px 15px;
    gap: 10px;
    border: 0px;
    border-radius: 8px;
    font-family: DM sans;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    background: #3787EB;
    cursor: pointer;
}

.button--disabled {
    background-color: #3788eb9a;
    cursor: auto;
} */

/* Base button styles */
.button {
    color: #FFFFFF;
    padding: 12px 15px;
    gap: 10px;
    border: 0;
    border-radius: 8px;
    font-family: 'DM Sans', sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Button types */
.button--primary {
    background-color: #3787EB;
    font-size: 12px;
    padding: 8px 15px;
}

.button--default {
    background-color: #6c757d;
}

.button--danger {
    background-color: #dc3545;
}

.button--trasperant {
    background-color: white;
    border: 1px solid #ccc;
}

/* Hover states */
.button--primary:hover {
    background-color: #2b6bb8;
}

.button--default:hover {
    background-color: #5a6268;
}

.button--danger:hover {
    background-color: #c82333;
}

/* Disabled state */
.button--disabled {
    background-color: #d6d6d6;
    cursor: auto;
}
.button__image {
    display: block;
}