.SearchInput{
    border: 1px solid rgba(232, 232, 232, 1);
    border-radius: 6px;
    display: flex;
    align-items: center;
    max-width: 148px;
}
.SearchInput img{
    padding-left: 10px;
}
.SearchInput input{
    border:0px;
    max-width: 94px;
    padding: 10px;
    font-size: 12px;
}

.SearchInput input:focus{
    outline: none;
    border: none;
}