.circular-progress-bar {
  display: flex;
  align-items: center;
}
  
  .circular-progress-bar svg {
    transform: rotate(-90deg);
  }
  
  .circular-progress-bar__circle {
    fill: none;
    stroke-width: 3;
  }
  
  .circular-progress-bar__circle--background {
    stroke: #e6e6e6;
  }
  
  .circular-progress-bar__circle--progress {
    stroke-width: 4;
    stroke: #45C375;
    stroke-linecap: round;
    transition: stroke-dasharray 0.3s;
  }
 