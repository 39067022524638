.range-bar {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  
  .range-bar__input {
    -webkit-appearance: none;
    width: 238px;
    height: 8px;
    background: linear-gradient(to right, #46C375 0%, #46C375 100%);
    background-repeat: no-repeat;
    background-color: rgba(228, 228, 228, 1);
    border-radius: 5px;
    outline: none;
    cursor: pointer;
    margin: 0;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.15) inset;

  }
  
  .range-bar__input::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    background: #46C375;
    border-radius: 50%;
    cursor: pointer;
    background: url("../../../assets/images/svg/Rang_thumb.svg")  no-repeat center center;
    /* filter: invert(41%) sepia(85%) saturate(254%) hue-rotate(103deg) brightness(92%) contrast(95%); */
  }
  
  .range-bar__input::-moz-range-thumb {
    width: 24px;
    height: 24px;
    background: #46C375;
    border-radius: 50%;
    cursor: pointer;
  }
  
  


