.input{
    gap: 0px;
    border-radius: 4px;
    border: 1px solid #F0F1F3;
    padding: 7px 0px 7px 20px;
    font-family: DM sans;
    font-size: 16px;
    font-weight: 500;
}
.input::placeholder{
    color: #9A9A9A;
}